<template>
  <div>
    <v-row>
      <v-col cols="4" xl="4" md="4" lg="4" xs="12" sm="12">
        <v-autocomplete
          label="Cliente"
          :items="dataClientes"
          :item-text="nombreCliente"
          item-value="idCliente"
          clearable
        ></v-autocomplete>
      </v-col>
      <v-col cols="4" xl="4" md="4" lg="4" xs="12" sm="12">
        <v-autocomplete
          label="Tipo"
          item-text="clave"
          item-value="idTrabajoOrden"
          clearable
        ></v-autocomplete>
      </v-col>
      <v-col cols="4" xl="4" md="4" lg="4" xs="12" sm="12">
        <v-autocomplete
          label="Moneda"
          :item-text="nombrePersona"
          item-value="idPersona"
          clearable
        ></v-autocomplete>
      </v-col>
      <v-col cols="12">
        <v-data-table :headers="headersDetalles" :items="dataPreOrdenes" :search="search" :loading="loadingTable">
          <template v-slot:[`item.monto`]="{ item }">
            $ {{ formatearCifra(item.monto) }}
          </template>
          <template v-slot:[`item.acciones`]="{ item }">
              <v-tooltip bottom color="black" class="white--text">
                <template v-slot:activator="{ on, attrs }">
                  <!-- Se muestra el ícono de palomita o de acción según la condición -->
                  <v-icon
                    v-on="on"
                    v-bind="attrs"
                    color="primary"
                    @click="item.ordenesMX <= 0 ? abrirConfirmacion(item) : null"
                  >
                    {{ item.ordenesMX <= 0 ? 'mdi-receipt-text-check' : 'mdi-check-circle' }}
                  </v-icon>
                </template>
                <span class="white--text">
                  {{ item.ordenesMX <= 0 ? 'Crear Receta' : 'Orden Creada' }}
                </span>
              </v-tooltip>
              <v-tooltip bottom color="black" class="white--text" v-if="item.ordenesMX > 0">
                <template v-slot:activator="{ on, attrs }">
                  <!-- Se muestra el ícono de palomita o de acción según la condición -->
                  <v-icon v-on="on" v-bind="attrs" color="primary" @click="generarPDF(item)" style="cursor: pointer;">
                    mdi-file-pdf-box
                  </v-icon>
                </template>
                <span class="white--text">
                  Generar PDF
                </span>
              </v-tooltip>
            </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog v-model="confirmacion" max-width="600px" persistent>
      <v-card>
        <v-card-title class="headline">¿Desea mandar esta Orden a Producción?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="cerrarConfirmacion()">Cancelar</v-btn>
          <v-btn color="red darken-1" text @click="postReceta()">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="successDialog" max-width="600px" persistent>
      <v-card>
        <v-card-title class="headline">Se ha procesado correctamente a ordenes</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="closeDialog()" :enabled="timer > 0">Aceptar ({{ timer }})</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
    <Cotizacion ref="dialogCotizacion" :idPreCotizacion="PCotizacionId" @succes_cotizacion="succesCotizacion()"/>
  </div>
</template>

<script>
import axios from 'axios'
import datePicker from "@/components/DatePicker";
import AlertSnackbar from '@/components/AlertSnackbar.vue'
import generarReceta from './GenerarReceta.vue'
import PreOrdenes from '../Cotizaciones/PreOrdenes.vue';
import Cotizacion from '../PreCotizacion/Cotizacion.vue';

export default {
  components: {
    generarReceta,
    PreOrdenes,
    datePicker,
    Cotizacion
  },
  data() {
    return {
      successDialog: false,
      timer: 3,  // Inicia el contador en 3 segundos
      propsSearch: {
        clearable: true,
        class: "v-input__slot",
        label: "Buscar",
        clearIcon: 'mdi-close-circle',
        appendIcon: 'mdi-magnify',
        hideDetails: true,
        dense: true,
      },
      nombreCliente(lista) {
        if (lista.clave) {
        return `[${lista.clave}] - ${lista.nombre}`;
        } else {
        return lista.nombre;
        }
      },
      

      search: '',
      headersDetalles: [
        {
        text: "Cotización",
        value: "idCotizacion",
        align: "center",
        sortable: false,
        }, {
        text: "Fecha Cotización",
        value: "fechaCreacion",
        align: "center",
        sortable: false,
        }, {
        text: "Trabajo",
        value: "nombreTrabajo",
        align: "center",
        sortable: false
        },/*{
        text: "Cotizado Por",
        value: "cotizadoPor",
        align: "center",
        sortable: false,
        },*/{
        text: "Monto",
        value: "precio",
        align: "center",
        sortable: false,
        }, {
        text: "Piezas",
        value: "cantidadPza",
        align: "center",
        sortable: false,
        }, /*{
        text: "Estado",
        value: "receta",
        align: "center",
        sortable: false,
        },*/ {
        text: "Acciones",
        value: "acciones",
        align: "center",
        sortable: false,
        },
    ],

      dataDesglosado: [
        { text: 'Si', value: true}, 
        { text: 'No', value: false},
      ],
      dataFormaPago: [
        { text: 'Efectivo', value: 1}, 
        { text: 'Tarjeta', value: 2},
        { text: 'Otro', value: 3},
      ],
      
      dataClientes: [],
      cotizacionesPre: [],
      PCotizacionId: null,
      idCotizacion: null,
      POrdenA: null,
      ordenesMX: null,
      idOrdenA: null,
      prueba: [],
      dataCotizaciones: [],
      dataReceta: {},
      dataPreOrdenes: [],
      confirmacion : false,
      dataCostos: [],
      dataTransporte: [],
      dataCotizacion: [],
      idPreOrden: null,
      idPreorden: null,
      ordenIndi: [],
      idOrden: null,

      idxPaso: 1,

      dialog: false,

      loadingTable: false,
      loadingReceta: false,
    }
  },
  watch: {
    timer(newVal) {
      if (newVal === 0) {
        this.closeDialog();
      }
    }
  },
  mounted() {
    this.startTimer();
  },
  methods: {
    initialize() {
      //this.getCotizaciones();
      this.getClientes();
      this.getPreOrdenes();
      //this.getPreOrdenes();
      this.loadingReceta = false;
    },
    mostrarDialogo() {
      this.successDialog = true;
      this.timer = 3;  // Reinicia el contador a 3 cada vez que se muestra el diálogo
      const interval = setInterval(() => {
        if (this.timer > 0) {
          this.timer -= 1;
        } else {
          clearInterval(interval);
          this.closeDialog();
        }
      }, 1000); // Actualiza el contador cada segundo
    },
    closeDialog() {
      this.successDialog = false;
      this.getPreOrdenes();
    },
    getPreOrdenes() {  //Obtenemos preordenes pa poder usar su "idOrdenCotizacion"
    //this.loadingTable = true;
    axios
      .get("/PreOrden/Listar?Estatus=1")
      .then((response) => {
          this.dataPreOrdenes = response.data.map((ordenes) => ({
            ...ordenes,

            idOrdenCotizacion: ordenes.idOrdenCotizacion,
            folioCotizacion: ordenes.orden.folioCotizacion,
            fechaPromesa: ordenes.orden.fechaPromesa,
            nombreTrabajo: ordenes.orden.nombreTrabajo,
            cantidadPza: ordenes.orden.cantidadPza,
            precio: ordenes.orden.precio,
            anticipo: ordenes.orden.anticipo,
            referenciaPago: ordenes.orden.referenciaPago,
          }));
          //console.log("todas: ", this.dataPreOrdenes);
      })
      .catch((error) => {
          this.loadingTable = false;    
          console.log(error.response.status);
      });
    },

    getClientes() {
      axios
        .get("/Clientes")
        .then(response => { this.dataClientes = response.data 
        //console.log("Soy de Geometrika");
        })
        .catch(error => { console.log(error.response) })
    },
    abrirConfirmacion(item) {
      this.confirmacion = true;
      this.PCotizacionId = item.idCotizacion;
      this.POrdenA = item.idOrdenA;
      this.ordenesMX = item.ordenesMX;
      //console.log(this.ordenesMX);
      //this.getMostrarCotizacionPre();
      //console.log(item);
      //console.log("soy el id en general", this.PCotizacionId);
    },
    cerrarConfirmacion() {
      this.confirmacion = false;
    },
    postReceta() {
      this.idCotizacion = this.PCotizacionId;
      this.idOrdenA = this.POrdenA;
      axios
      .get(`/PreOrden/GuardarReceta/${this.idCotizacion}/${this.idOrdenA}`)
      .then((response) => {
        if (response.status === 200) {
          this.mostrarDialogo(); 
          this.confirmacion = false;
          item.ordenesMX = 1;
        }
      })
      .catch((error) => {
        console.log(error)
      })
    },
    getMostrarCotizacionPre() {  //También pa mostrar datos al momento de editar
      axios
        .get(`/PreOrden/Mostrar/`,{params:{IdOrden: this.idPreOrden}})
        
        .then((response) => {
          if (response.status == 204) {
            this.$emit("error_mostrarCotizacion", 204);
          }
          else {
            this.dataCotizacion = response.data.Datos;
            this.dataCostos = response.data.Calculos;
            this.getTransporte();
            //this.dialog = true;
            //console.log("nuevainfo", this.dataCotizacion);
          }
        })
        .catch((error) => {
          this.$emit("error_mostrarCotizacion", error);
          this.dialog = false;
        })
    },

    getDataOrden(id) {  //Obtención de pre-orden individual a editar.
    this.loadingPreOrden = true;
    return new Promise((resolve, reject) => {
      axios
        .get("/PreOrden/Listar")
        .then((response) => {
            this.dataOrdenes = response.data;
            const ordenEncontrada = this.dataOrdenes.find(orden => orden.idOrdenCotizacion === id);

            if (ordenEncontrada) {
                this.dataOrden = ordenEncontrada.orden;
                this.estatus = ordenEncontrada.estatus;
                this.dataOrdenn = { ...this.dataOrden };
                resolve(); 
            } else {
                console.error("Orden no encontrada con id:", id);
                reject("Orden no encontrada");
            }

            this.loadingPreOrden = false;
            //console.log("general: ", this.dataOrdenes);
            //console.log("ordenesss:", this.dataOrdenn);
            //console.log("el estatus:", this.estatus);
        })
        .catch((error) => {
            console.log(error);
            this.loadingPreOrden = false;
            reject(error);
        });
    });
    },

    getTransporte() {
      axios
        .get("/TransportePreguntas/Listar")
        .then((response) => {
          this.dataTransporte = response.data
        })
        .catch((error) => { console.log(error); });
    },
    alerta(code) {
      this.$refs.AlertSnackbar.state_alert(code);
    },
    formatearCifra(number, decimales = 2) {
      let numero = 0;
      if (number != null) numero = number;
      return numero.toLocaleString('en-US', { minimumFractionDigits: decimales, maximumFractionDigits: decimales });
    },

    show_editCotizacion(item) {
      if (item) {
          this.$refs.dialogCotizacion.show_editCotizacion(this.PCotizacionId, item.idCotizacion);
          //console.log(item);
          //console.log(this.PCotizacionId);
      }
    },
   
    error_Cotizacion(error) {
    this.alerta(error)
    },

    succesCotizacion() {
    //this.alerta(200)
    this.getPrecotizaciones();
    this.getCotizaciones()
    },
    generarPDF(item) {
      this.POrdenA = item.idOrdenA;

      if (!this.POrdenA) return;

      const url = `/PreOrden/GenerarOrdenPDF?idOrden=${this.POrdenA}`;
      // Obtener la fecha y hora actual en el formato deseado
      const currentDate = new Date();
      const formattedDate = currentDate.toISOString().slice(0, 10); // Formato: YYYY-MM-DD
      const formattedTime = currentDate.toTimeString().slice(0, 8).replace(/:/g, '-'); // Formato: HH-MM-SS
      const fileName = `Orden-${this.POrdenA}_${formattedDate}_${formattedTime}.pdf`;
      this.$utils.axios.downloadFile(
        url,
        "GET",
        fileName, // Nombre del archivo con ID y fecha
        "application/pdf" // Tipo MIME para PDF
      );
    },
  },
  // computed: {
  //   jsonImpresiones() {
  //     const dataImpresiones = this.dataImpresiones.map(item => {
  //       return {
  //         idCara: item.idCara,
  //         idTintaTipo: item.idTintaTipo,
  //         idArticulo: item.idArticulo,
  //         idProveedor: item.idProveedor,
  //         porcentajeTinta: parseInt(item.porcentajeTinta)
  //       }
  //     })
  //     return dataImpresiones
  //   },
  //   jsonConceptos() {
  //     const dataConceptos = this.dataExtras.map(item => {
  //       return {
  //         concepto: item.concepto,
  //         tipo: parseInt(item.tipo),
  //         monto: parseFloat(item.monto),
  //         descripcion: item.descripcion,
  //       }
  //     })
  //     return dataConceptos
  //   },
  //   jsonTransporte() {
  //     const dataTransporte = this.dataTransporte.map(item => ({
  //       idTransportePregunta: item.idTransportePregunta,
  //       respuesta: item.respuesta,
  //       monto: item.monto
  //     }));
  //     return dataTransporte;
  //   },
  //   jsonInsumosImpresion() {
  //     let insumos = [];
  //     if (this.dataCotizacion.impresionInsumos) {

  //       insumos = this.dataInsumos.filter(item => item.idMaquina === this.dataCotizacion.idImpresionMaquina)

  //       return insumos.map(item => {
  //         const insumoN = this.insumoCotizaciones.find(aplicaInsumo => aplicaInsumo.idMaquinaInsumos === item.idMaquinaInsumos);
  //         return {
  //           idMaquinaInsumos: item.idMaquinaInsumos,
  //           idMaquina: item.idMaquina,
  //           insumo: item.insumo,
  //           tipoInsumo: item.tipoInsumo,
  //           costo: item.costo,
  //           aplicaInsumo: insumoN ? insumoN.aplicaInsumo : false,
  //         };
  //       });
  //     }
  //     return insumos;
  //   },
  //   jsonMermas() {
  //     const mermas = [];
  //     if (this.dataImpresiones.length > 0) {
  //       mermas.push({
  //         idProceso: this.dataMaquinasImpresoras.find(item => item.proceso === "Impresión").idProceso,
  //       })
  //     }
  //     if (this.dataCotizacion.barniz) {
  //       mermas.push({
  //         idProceso: this.dataMaquinasBarniz.find(item => item.proceso === "Barnizado").idProceso,
  //       })
  //     }
  //     if (this.dataCotizacion.uvPlasta) {
  //       mermas.push({
  //         idProceso: this.dataMaquinasUvPlasta.find(item => item.proceso === "UV Plasta").idProceso,
  //       })
  //     }
  //     if (this.dataCotizacion.uvRegistro) {
  //       mermas.push({
  //         idProceso: this.dataMaquinasUvRegistro.find(item => item.proceso === "UV Registro").idProceso,
  //       })
  //     }
  //     if (this.dataCotizacion.plastificado) {
  //       mermas.push({
  //         idProceso: this.dataMaquinasPlastificado.find(item => item.proceso === "Plastificado").idProceso,
  //       })
  //     }
  //     if (this.dataCotizacion.suajado) {
  //       mermas.push({
  //         idProceso: this.dataMaquinasSuaje.find(item => item.proceso === "Suajado").idProceso,
  //       })
  //     }
  //     if (this.dataCotizacion.corte) {
  //       mermas.push({
  //         idProceso: this.dataMaquinasCorte.find(item => item.proceso === "Corte").idProceso,
  //       })
  //     }
  //     if (this.dataCotizacion.empalme) {
  //       mermas.push({
  //         idProceso: this.dataMaquinasEmpalme.find(item => item.proceso === "Empalme").idProceso,
  //       })
  //     }
  //     if (this.dataCotizacion.pegado) {
  //       mermas.push({
  //         idProceso: this.dataMaquinasPegado.find(item => item.proceso === "Pegado Lineal").idProceso,
  //       })
  //     }
  //     if (this.dataCotizacion.pegadoGolpe) {
  //       mermas.push({
  //         idProceso: this.dataMaquinasPegadoGolpe.find(item => item.proceso === "Pegado de Golpe").idProceso,
  //       })
  //     }
  //     if (this.dataCotizacion.troquel) {
  //       switch (this.dataCotizacion.idTroquelTipo) {
  //         case 1:
  //           mermas.push(
  //             {
  //               idProceso: this.dataMaquinasTroquelBobina.find(item => item.proceso === "Troquelado Bobina").idProceso,
  //             })
  //           break
  //         case 2:
  //           mermas.push(
  //             {
  //               idProceso: this.dataMaquinasTroquelManual.find(item => item.proceso === "Troquelado Manual").idProceso,
  //             })
  //           break
  //         case 3:
  //           mermas.push(
  //             {
  //               idProceso: this.dataMaquinasTroquelAutomatica.find(item => item.proceso === "Troquelado Automatica").idProceso,
  //             })
  //           break
  //       }
  //     }
  //     return mermas;
  //   },
  // },
  mounted() {
    this.initialize();
  }
}
</script>

