<template>
  <div v-if="permisoVista('cotizaciones','r')">
    <v-btn v-if="permisoVista('cotizaciones','c')" color="primary" @click="mostrarOpcionesPre()">Crear nueva pre-cotización</v-btn>
    <v-data-table :headers="headers" :items="dataPreCotizaciones" :search="search" :loading="loadingTable">
    <template v-slot:top>
        <div class="d-flex align-center">
        <v-text-field label="Buscar" class="mb-4" v-model="search" v-bind="propsSearch" />
        <v-autocomplete v-model="selectCliente" :items="dataClientes" :item-text="nombreCliente"
            item-value="idCliente" label="Cliente" @change="cambioUrlPreCotizacion()" v-bind="propsAutocomplete"
            class="col-3" />
        <v-autocomplete v-model="selectEstado" :items="dataEstados" :item-text="nombreEstado" item-value="id"
            label="Estado" @change="cambioUrlPreCotizacion()" v-bind="propsAutocomplete" class="col-3" />
        </div>
    </template>
    <template v-slot:[`item.folioRelacionado`]="{ item }">
        {{ item.folioRelacionado }}
        <v-tooltip v-if="permisoVista('cotizaciones','u')" bottom color="black">
        <template v-slot:activator="{ on, attrs }">
            <v-icon v-on="on" v-bind="attrs" small class="mr-2" @click="show_dialogFolio(item)">mdi-pencil</v-icon>
        </template>
        <span class="white--text">Editar</span>
        </v-tooltip>
    </template>
    <!-- <template v-slot:[`item.tipoPrecotizacion`]="{ item }">
        {{ item.tipoPrecotizacion }}
    </template> -->
    <template v-slot:[`item.cantidad`]="{ item }">
        {{ item.cantidad }} {{ item.unidad }}
    </template>
    <template v-slot:[`item.estado`]="{ item }">
        <div v-if="item.estado === 0" style="font-size: 14px !important;">Pendiente</div>
        <div v-if="item.estado === 1" style="font-size: 14px !important;">Realizado</div>
    </template>
    <template v-slot:[`item.acciones`]="{ item }">

        <v-tooltip bottom color="black" v-if="permisoAgregar(item)">
        <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" small class="mr-2" @click="show_dialogCotizacionG(item)"> mdi-plus
            </v-icon>
        </template>
        <span class="white--text">Agregar Cotización</span>
        </v-tooltip>

        <v-tooltip bottom color="black" v-if="permisosVer(item)">
        <template v-slot:activator="{ on, attrs }">
            <v-icon v-on="on" v-bind="attrs" small class="mr-2" @click="mostrarDetalles(item)"> mdi-eye </v-icon>
        </template>
        <span class="white--text">Ver Detalles</span>
        </v-tooltip>

        <v-tooltip bottom color="black" v-if="permisoCompartir(item)">
        <template v-slot:activator="{ on, attrs }">
            <v-icon v-on="on" v-bind="attrs" small @click="show_dialogCompartir(item)"> mdi-swap-horizontal </v-icon>
        </template>
        <span class="white--text">Compartir</span>
        </v-tooltip>
    </template>
    </v-data-table>

    <v-dialog v-model="menuTipoPrecotizacion" max-width="500px">
    <v-card>
        <v-card-title> Crear Nueva Pre-Cotización </v-card-title>
        <v-card-text align="center">
        <v-btn-toggle v-model="groupButtons" mandatory>
            <v-btn value="1" style="width: 200px;" :class="btnClass">General</v-btn>
            <v-btn value="2" style="width: 200px;" :class="btnClass">Vasos</v-btn>
        </v-btn-toggle>

        <div v-if="groupButtons >= 2" mandatory>
            <v-btn-toggle v-model="btnsTipoVaso">
            <v-btn value="2" :class="btnClass">Pared Sencilla</v-btn>
            <v-btn value="3" :class="btnClass">Pared Doble</v-btn>
            </v-btn-toggle>
        </div>
        </v-card-text>
        <v-card-actions class="d-flex justify-content-end">
        <v-btn color="blue darken-1" background="primary" class="ml-auto" text
            @click="ocultarOpcionesPre()">Cancelar</v-btn>
        <v-btn color="error" text @click="mostrarDialogPreCot()">Iniciar Pre-Cotización</v-btn>
        </v-card-actions>
    </v-card>
    </v-dialog>

    <v-dialog v-model="dialogFolio" persistent max-width="500px">
    <v-card>
        <v-card-title>Modificar Folio Relacionado</v-card-title>
        <v-card-text>
        <v-text-field v-model="PCotizacionFolio" label="Folio Relacionado" v-bind="inputTextProps" />
        </v-card-text>
        <v-card-actions>
        <v-btn :loading="loadingFolio" text color="blue darken-1" class="ml-auto"
            @click="hide_dialogFolio()">Cancelar</v-btn>
        <v-btn :loading="loadingFolio" text color="error" @click="putFolioRelacionado()">Guardar</v-btn>
        </v-card-actions>
    </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDetalles" persistent max-width="75%">
    <v-card>
        <v-card-title style="margin-bottom: 10px">
        Detalles de la Pre Cotización {{ dataDetallesCotizaciones.folio }}
        </v-card-title>
        <v-card-subtitle>
        <v-row>
            <v-col class="col-6">
            <p style="display: inline-block; margin-bottom: 0">Folio:</p>
            {{ dataDetallesCotizaciones.folio }}<br />
            <p style="display: inline-block; margin-bottom: 0">
                Folio Relacional:
            </p>
            {{ dataDetallesCotizaciones.folioRelacionado }}<br />
            <p style="display: inline-block; margin-bottom: 0">Cliente:</p>
            {{ dataDetallesCotizaciones.cliente }}<br />
            <p style="display: inline-block; margin-bottom: 0">Trabajo:</p>
            {{ dataDetallesCotizaciones.trabajo }}
            </v-col>
            <v-col class="col-6">
            <p style="display: inline-block; margin-bottom: 0">Contacto:</p>
            {{ dataDetallesCotizaciones.contacto }}<br />
            <p style="display: inline-block; margin-bottom: 0">Cotizó:</p>
            {{ dataDetallesCotizaciones.cotizo }}<br />
            <p style="display: inline-block; margin-bottom: 0">Piezas:</p>
            {{ dataDetallesCotizaciones.cantidad }}<br />
            <p style="display: inline-block; margin-bottom: 0">Estado: </p>

            <div v-if="dataDetallesCotizaciones.estado === 0"
                style="font-size: 14px !important;display: inline-block; margin-bottom: 0"> {{ ' Pendiente' }}</div>
            <div v-if="dataDetallesCotizaciones.estado === 1"
                style="font-size: 14px !important;display: inline-block; margin-bottom: 0"> {{ ' Realizado' }}</div>
            </v-col>
        </v-row>
        </v-card-subtitle>
        <v-card-text>
        <v-data-table :headers="headersDetalles" :items="cotizacionesPre" :loading="loadingDetalles">
            <template v-slot:[`item.acciones`]="{ item }">
            <v-tooltip bottom color="black" v-if="permisoActualizarCot">
                <template v-slot:activator="{ on, attrs }">
                <v-icon v-on="on" v-bind="attrs" small class="mr-2" @click="show_editCotizacion(item)">mdi
                    mdi-plus</v-icon>
                </template>
                <span class="white--text">Crear A Partir De</span>
            </v-tooltip>
            <v-tooltip bottom color="black" v-if="permisoVerDatos">
                <template v-slot:activator="{ on, attrs }">
                <v-icon v-on="on" v-bind="attrs" class="mr-2" small
                    @click="show_mostrarCotizacion(item)">mdi-eye</v-icon>
                </template>
                <span class="white--text">Mostrar Cotización</span>
            </v-tooltip>
            <v-tooltip bottom color="black" v-if="permisoVerCalculos">
                <template v-slot:activator="{ on, attrs }">
                <v-icon v-on="on" v-bind="attrs" class="mr-2" small
                    @click="show_mostrarCalculos(item)">mdi-currency-usd</v-icon>
                </template>
                <span class="white--text">Mostrar Cálculos</span>
            </v-tooltip>
            <v-tooltip bottom color="black" v-if="permisoVerCalculos">
                <template v-slot:activator="{ on, attrs }">
                <v-icon v-on="on" v-bind="attrs" class="mr-2" small @click="iniciarPreorden(item)">mdi-file-send</v-icon>
                </template>
                <span class="white--text">Preordenar</span>
            </v-tooltip>
            <v-tooltip bottom color="black" v-if="permisoVerCalculos">
                <template v-slot:activator="{ on, attrs }">
                 <v-icon v-on="on" v-bind="attrs" small @click="descargarPDF(item.idCotizacion)">mdi-file-pdf-box</v-icon>
                </template>
                <span class="white--text">Descargar PDF</span>
            </v-tooltip>
            <v-tooltip bottom color="black" v-if="permisoVerCalculos">
                <template v-slot:activator="{ on, attrs }">
                <v-icon v-on="on" v-bind="attrs" class="mr-2" small @click="infoCliente(item)">mdi-clipboard-text</v-icon>
                </template>
                <span class="white--text">Detalles</span>
            </v-tooltip>
            </template>
            <template v-slot:[`item.monto`]="{ item }">
            $ {{ formatearCifra(item.monto) }}
            </template>
            <template v-slot:[`item.piezas`]="{ item }">
            {{ item.piezas }} {{ item.unidad }}
            </template>
            <!--<template v-slot:[`item.receta`]="{ item }">
            <v-tooltip v-if="item.receta" bottom color="black">
                <template v-slot:activator="{ on, attrs }">
                <v-icon color="success" v-bind="attrs" v-on="on">
                    mdi-check-bold
                </v-icon>
                </template>
                <span class="white--text">Receta creada</span>
            </v-tooltip>
            <v-tooltip v-else bottom color="black">
                <template v-slot:activator="{ on, attrs }">
                <v-icon color="orange" v-bind="attrs" v-on="on">
                    mdi-clock-time-eight
                </v-icon>
                </template>
                <span class="white--text">Sin Receta</span>
            </v-tooltip>
            </template>-->
        </v-data-table>
        </v-card-text>
        <v-card-actions>
        <v-btn class="ml-auto" color="primary" text @click="ocultarDetalles()">Cerrar</v-btn>
        </v-card-actions>
    </v-card>
    </v-dialog>
    <v-dialog v-model="dialogCompartir" persistent max-width="75%">
    <v-card>
        <v-card-title>Compartir '{{ preCompartirNombre }}'</v-card-title>
        <v-card-text>
        <v-data-table :headers="headersCompartidos" :items="dataUsuariosCompartidos" hide-default-footer>
            <template v-slot:[`item.idUsuarioRecibe`]="{ item }">
            <v-autocomplete v-model="item.idUsuarioRecibe" :items="dataUsuarios" item-text="nombre"
                item-value="idUsuario" label="Usuario" />
            </template>
            <template v-slot:[`item.crearPrecotizacion`]="{ item }">
            <div class="d-flex justify-center">
                <v-switch v-model="item.crearPrecotizacion" :label="item.crearPrecotizacion ? `Si` : `No`" />
            </div>
            </template>
            <template v-slot:[`item.calculosCotizacion`]="{ item }">
            <div class="d-flex justify-center">
                <v-switch v-model="item.calculosCotizacion" :label="item.calculosCotizacion ? `Si` : `No`" />
            </div>
            </template>
            <template v-slot:[`item.datosCotizacion`]="{ item }">
            <div class="d-flex justify-center">
                <v-switch v-model="item.datosCotizacion" :label="item.datosCotizacion ? `Si` : `No`" />
            </div>
            </template>
            <template v-slot:[`item.actualizarCotizacion`]="{ item }">
            <div class="d-flex justify-center">
                <v-switch v-model="item.actualizarCotizacion" :label="item.actualizarCotizacion ? `Si` : `No`" />
            </div>
            </template>
            <template v-slot:[`item.actualizarCotizacion`]="{ item }">
            <div class="d-flex justify-center">
                <v-switch v-model="item.actualizarCotizacion" :label="item.actualizarCotizacion ? `Si` : `No`" />
            </div>
            </template>
            <template v-slot:[`item.acciones`]="{ item }">
            <v-tooltip bottom color="black">
                <template v-slot:activator="{ on, attrs }">
                <v-icon v-on="on" v-bind="attrs" @click="removeUsuario(item)"
                    :loading="loadingCompartir">mdi-trash-can</v-icon>
                </template>
                <span class="white--text">Eliminar</span>
            </v-tooltip>
            </template>
            <template v-slot:footer>
            <v-btn color="primary" @click="addUsuario()" :loading="loadingCompartir">Agregar Usuario</v-btn>
            </template>
        </v-data-table>
        </v-card-text>
        <v-card-actions>
        <v-btn text class="ml-auto" color="success" @click="hide_dialogCompartir()"
            :loading="loadingCompartir">Cancelar</v-btn>
        <v-btn text color="error" @click="putActualizarCompartir()" :loading="loadingCompartir">Actualizar</v-btn>
        </v-card-actions>
    </v-card>
    </v-dialog>
    <v-dialog v-model="mostrarCliente" persistent fullscreen hide-overlay transition="dialog-bottom-transition">
        <v-card>
         <v-toolbar color="primary">
           <v-spacer />
           <v-toolbar-title class="white--text">Información de Cotización</v-toolbar-title>
           <v-spacer />
           <v-icon dark @click="cerrarCliente()">mdi-close</v-icon>
         </v-toolbar>
         <v-list-item style="width: 98%; margin: auto" v-if="mostrarCliente">
           <v-list-item-content>
            <v-list-item-title class="my-title">Datos del Trabajo:</v-list-item-title>
            <v-card v-bind="cardStyle">
              <v-card-text>
                <v-row class="col-12">
                  <v-col cols="12">
                    <h6 class="font-weight-bold d-inline">Nombre del Trabajo: </h6> {{ dataCostos.trabajoMostrar.nombreTrabajo }}
                  </v-col>
                  <v-col cols="4">
                    <h6 class="font-weight-bold d-inline">Cantidad Solicitada:</h6>
                    {{ formatearCifra(dataCostos.trabajoMostrar.cantidadPza, 0) }} Pzas.
                  </v-col>
                  <v-col cols="4">
                    <h6 class="font-weight-bold d-inline">Largo Pieza:</h6> {{ dataCostos.trabajoMostrar.pzIndividualLargo }}
                  </v-col>
                  <v-col cols="4">
                    <h6 class="font-weight-bold d-inline">Ancho Pieza:</h6> {{ dataCostos.trabajoMostrar.pzIndividualAncho }}
                  </v-col>
                  <v-col cols="4">
                    <h6 class="font-weight-bold d-inline">Piezas Pinza:</h6> {{ dataCostos.trabajoMostrar.piezasPinza }}
                  </v-col>
                  <v-col cols="4">
                    <h6 class="font-weight-bold d-inline">Piezas Alto:</h6> {{ dataCostos.trabajoMostrar.piezasAlto }}
                  </v-col>
                  <v-col cols="4" />
                  <v-col cols="6">
                    <h6 class="font-weight-bold d-inline">Cantidad de Pliegos:</h6>
                    {{ formatearCifra(Math.ceil(dataCostos.trabajoMostrar.numeroPliegos), 0) }}
                  </v-col>
                  <v-col cols="6">
                    <h6 class="font-weight-bold d-inline">Medida:</h6> {{ dataCostos.trabajoMostrar.medidas }}
                    Mts.
                  </v-col>
                  <v-col cols="6">
                    <h6 class="font-weight-bold d-inline">Papel:</h6> {{ dataCostos.trabajoMostrar.tipoPapel }}
                  </v-col>
                  <v-col cols="3">
                    <h6 class="font-weight-bold d-inline">Gramaje de Papel:</h6>
                    {{ formatearCifra(dataCostos.trabajoMostrar.pesoPapel, 3) }} Grs.
                  </v-col>
                  <v-col cols="3">
                    <h6 class="font-weight-bold d-inline">Proveedor de Papel:</h6> {{ dataCostos.trabajoMostrar.proveedorPapel }}
                  </v-col>
                  <v-col cols="3">
                    <h6 class="font-weight-bold d-inline">Medida del Pliego:</h6>
                  </v-col>
                  <v-col cols="3">
                    <h6 class="font-weight-bold d-inline">Base:</h6> {{ dataCostos.trabajoMostrar.base }}
                  </v-col>
                  <v-col cols="3">
                    <h6 class="font-weight-bold d-inline">Altura:</h6> {{ dataCostos.trabajoMostrar.altura }}
                  </v-col>
                  <v-col cols="12">
                    <h6 class="font-weight-bold d-inline">Tintas:</h6> {{ dataCostos.trabajoMostrar.numTintas }}
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            
            <v-list-item-title class="my-title">Precio - Venta:</v-list-item-title>
            <v-card v-bind="cardStyle">
              <br>
              <v-card-text>
                <v-col>
                  <v-row class="padding-hr">
                    <v-col cols="3">
                      <h6 class="font-weight-bold d-inline">Costo Total:</h6>
                    </v-col>
                    <v-col cols="2">$ {{ formatearCifra(dataCostos.precioVenta.costoTotal, 2) }}</v-col>
                    <v-col cols="2">
                      <h6 class="font-weight-bold d-inline">Utilidad:</h6>
                    </v-col>
                    <v-col cols="2">{{ }}%</v-col>
                  </v-row>
                  <hr color="#C9CFD1">
                </v-col>

                <v-col>
                  <v-row class="padding-hr">
                    <v-col cols="3">
                      <h6 class="font-weight-bold d-inline">Utilidad en pesos:</h6>
                    </v-col>
                    <v-col cols="2">$ {{ formatearCifra(dataCostos.precioVenta.utilidadPesos, 2) }}</v-col>
                  </v-row>
                  <hr color="#C9CFD1">
                </v-col>

                <v-col>
                  <v-row class="padding-hr">
                    <v-col cols="3">
                      <h6 class="font-weight-bold d-inline">Subtotal:</h6>
                    </v-col>
                    <v-col cols="2">$ {{ formatearCifra(dataCostos.precioVenta.subtotal, 2) }}</v-col>
                  </v-row>
                  <hr color="#C9CFD1">
                </v-col>

                <v-col>
                  <v-row class="padding-hr">
                    <v-col cols="3">
                      <h6 class="font-weight-bold d-inline">Envío a Planta:</h6>
                    </v-col>
                    <v-col cols="2">$ {{ formatearCifra(dataCostos.precioVenta.envioPlanta, 2) }}</v-col>
                  </v-row>
                  <hr color="#C9CFD1">
                </v-col>

                <v-col>
                  <v-row class="padding-hr">
                    <v-col cols="3">
                      <h6 class="font-weight-bold d-inline">Envío a Cliente:</h6>
                    </v-col>
                    <v-col cols="2">$ {{ formatearCifra(dataCostos.precioVenta.envioCliente, 2) }}</v-col>
                  </v-row>
                  <hr color="#C9CFD1">
                </v-col>

                <v-col>
                  <v-row class="padding-hr">
                    <v-col cols="3">
                      <h6 class="font-weight-bold d-inline">Herramental:</h6>
                    </v-col>
                    <v-col cols="2">$ {{ formatearCifra(dataCostos.precioVenta.herramental, 2) }}</v-col>
                  </v-row>
                  <hr color="#C9CFD1">
                </v-col>

                <v-col>
                  <v-row class="padding-hr">
                    <v-col cols="3">
                      <h6 class="font-weight-bold d-inline">Total:</h6>
                    </v-col>
                    <v-col cols="2">$ {{ formatearCifra(dataCostos.precioVenta.total, 2) }}</v-col>
                  </v-row>
                  <hr color="#C9CFD1">
                </v-col>

                <v-col>
                  <v-row class="padding-hr">
                    <v-col cols="3">
                      <h6 class="font-weight-bold d-inline">Precio por Millar:</h6>
                    </v-col>
                    <v-col cols="2">$ {{ formatearCifra(dataCostos.precioVenta.precioMillar, 2) }}</v-col>
                    <v-col cols="2">
                      <h6 class="font-weight-bold d-inline">Costo Real:</h6>
                    </v-col>
                    <v-col cols="2">$ {{ formatearCifra(dataCostos.precioVenta.costoReal, 2) }}</v-col>
                    <v-col cols="2">
                      <h6 class="font-weight-bold d-inline">Utilidad Real:</h6>
                    </v-col>
                    <v-col cols="1">% {{ formatearCifra(dataCostos.precioVenta.utilidadReal, 2) }}</v-col>
                  </v-row>
                  <hr color="#C9CFD1">
                </v-col>

                <v-row>
                 <v-col cols="3">
                   <v-card style="width: auto;">
                     <v-card-subtitle>PRECIO TOTAL</v-card-subtitle>
                     <v-card-title>$ {{ formatearCifra(dataCostos.precioVenta.total, 2) }}</v-card-title>
                   </v-card>
                 </v-col>
                </v-row>
              </v-card-text>
            </v-card>
           </v-list-item-content>
         </v-list-item>
        </v-card> 
    </v-dialog>
    

    <PreCotizacion ref="PreCotizacion" @success_precotizacion="succesPreCotizacion();" />
    <Cotizacion ref="dialogCotizacion" :idPreCotizacion="PCotizacionId" @error_Cotizacion="error_Cotizacion"
    @succes_cotizacion="succesCotizacion()" />

    <MostrarCalculos ref="MostrarCalculos" :idOrden="idOrden" @error_mostrarCalculos="error_mostrarCalculos" />
    <MostrarCotizacion ref="MostrarCotizacion" :idOrden="idOrden" @error_mostrarCotizacion="error_mostrarCotizacion" />
    <Preordenar ref="Preordenar" :idOrden="idOrden" @error_mostrarCotizacion="error_mostrarCotizacion" />

    <AlertSnackbar ref="AlertSnackbar" />
  </div>
</template>

<script>
import axios from "axios"
import PreCotizacion from "../PreCotizacion/PreCotizacion.vue";
import Cotizacion from "../PreCotizacion/Cotizacion.vue";
import AlertSnackbar from '@/components/AlertSnackbar.vue';
import MostrarCalculos from "../PreCotizacion/MostrarCalculos.vue";
import MostrarCotizacion from "../PreCotizacion/MostrarCotizacion.vue";
import Preordenar from "../PreCotizacion/Preordenar.vue";
export default {
components: {
    PreCotizacion,
    Cotizacion,
    AlertSnackbar,
    MostrarCalculos,
    MostrarCotizacion,
    Preordenar
},
data() {
    return {
    cardStyle: {
    outlined: true,
    elevation: 5,
    style: {
        padding: "0px",
        width: "100%",
        border: "1px solid #C7C7C7",
     },
    },
    nombreCliente(lista) {
        if (lista.clave) {
        return `[${lista.clave}] - ${lista.nombre}`;
        } else {
        return lista.nombre;
        }
    },
    nombreEstado: (lista) => `${lista.value}`,
    propsSearch: {
        clearable: true,
        class: "v-input__slot",
        label: "Buscar",
        clearIcon: 'mdi-close-circle',
        appendIcon: 'mdi-magnify',
        hideDetails: true,
        dense: true,
    },
    propsAutocomplete: {
        outlined: false,
        dense: false,
        clearable: true,
        clearIcon: 'mdi-close-circle',
    },
    inputTextProps: {
        required: true,
        rules: [
        (v) => !!v || "Dato Requerido"
        ],
    },
    optionDialog: null,
    loadingTabla: false,
    dialogSuaje: false,
    dataEstatus: [
        {
        id: 1, value: "Activo",
        }, {
        id: 2, value: "Inactivo",
        }
    ],
    dialogFolio: false,
    dialogDetalles: false,
    dialogCompartir: false,

    dialogPreVasos: false,
    dialogPreGeneral: false,
    dialogCotizacionG: false,

    menuTipoPrecotizacion: false,
    dialogButtons2: false,
    groupButtons: null,
    btnsTipoVaso: null,
    optionButton: 0,
    btnClass: 'my-button-class',

    PCotizacionId: null,
    PCotizacionFolio: null,
    preCompartirNombre: null,
    preCompartirId: null,


    search: "",
    selectEstado: null,
    selectCliente: null,

    headers: [
        {
        text: "Folio",
        value: "folio",
        align: "center",
        filterable: true,
        sortable: false,
        }, {
        text: "Folio Relacionado",
        value: "folioRelacionado",
        align: "center",
        filterable: true,
        sortable: false,
        },{
        text: "Tipo",
        value: "tipoPrecotizacion",
        align: "center",
        filterable: true,
        sortable: false,
        }, {
        text: "Cliente",
        value: "cliente",
        align: "center",
        filterable: true,
        sortable: false,
        }, {
        text: "Trabajo",
        value: "trabajo",
        align: "center",
        filterable: true,
        sortable: false,
        }, {
        text: "Contacto",
        value: "contacto",
        align: "center",
        valign: "center",
        filterable: true,
        sortable: false,
        }, {
        text: "Cotizó",
        value: "cotizo",
        align: "center",
        filterable: true,
        sortable: false,
        }, {
        text: "Unidades",
        value: "cantidad",
        align: "center",
        filterable: true,
        sortable: false,
        }, {
        text: "Cotizaciones",
        value: "numCotizaciones",
        align: "center",
        filterable: true,
        sortable: false,
        }, {
        text: "Estado",
        value: "estado",
        align: "center",
        alignSelf: "center",
        filterable: true,
        sortable: false,
        }, {
        text: "Fecha de Pre Cotización",
        value: "fechaCreacion",
        align: "center",
        filterable: true,
        sortable: false,
        }, {
        text: "Acciones",
        value: "acciones",
        align: "center",
        alignSelf: "center",
        sortable: false,
        sortable: false,
        },
    ],
    headersDetalles: [
        {
        text: "Cotización",
        value: "idCotizacion",
        align: "center",
        sortable: false,
        }, {
        text: "Fecha Cotización",
        value: "fechaCreacion",
        align: "center",
        sortable: false,
        }, {
        text: "Trabajo",
        value: "nombreTrabajo",
        align: "center",
        sortable: false
        },/*{
        text: "Cotizado Por",
        value: "cotizadoPor",
        align: "center",
        sortable: false,
        },*/{
        text: "Monto",
        value: "monto",
        align: "center",
        sortable: false,
        }, {
        text: "Piezas",
        value: "piezas",
        align: "center",
        sortable: false,
        }, /*{
        text: "Estado",
        value: "receta",
        align: "center",
        sortable: false,
        },*/ {
        text: "Acciones",
        value: "acciones",
        align: "center",
        sortable: false,
        },
    ],
    headersCompartidos: [
        {
        text: "Usuario",
        value: "idUsuarioRecibe",
        align: "center",
        filterable: true,
        sortable: false,
        }, {
        text: "Crear Nueva Cotización",
        value: "crearPrecotizacion",
        align: "center",
        filterable: true,
        sortable: false,
        }, {
        text: "Ver Cálculos",
        value: "calculosCotizacion",
        align: "center",
        filterable: true,
        sortable: false,
        }, {
        text: "Ver Cotización",
        value: "datosCotizacion",
        align: "center",
        filterable: true,
        sortable: false,
        }, {
        text: "Crear Cotización A Partir De",
        value: "actualizarCotizacion",
        align: "center",
        filterable: true,
        sortable: false,
        }, {
        text: "Acciones",
        value: "acciones",
        align: "center",
        filterable: true,
        sortable: false,
        }
    ],

    dataPreCotizaciones: [],
    dataClientes: [],
    dataEstados: [
        {
        id: 0,
        value: "Pendiente"
        }, {
        id: 1,
        value: "Realizado"
        }
    ],
    dataUsuariosCompartidos: [],
    dataUsuarios: [],

    loadingTable: false,
    loadingDetalles: false,
    loadingFolio: false,
    loadingCompartir: false,

    urlPreCotizacion: "/Precotizaciones/Listar?",
    urlPreCotizacionDefault: "/Precotizaciones/Listar",

    cotizacionesPre: [],
    dataDetallesCotizaciones: [],

    valueDefault: null,

    idOrden: null,

    idUsuario: null,
    permisoVerDatos: false,
    permisoVerCalculos: false,
    permisoActualizarCot: false,

    mostrarCliente: false,
    dataCostos: [],
    };
},
methods: {
    initialize() {
    this.getPrecotizaciones();
    this.getUsuarios();
    this.getClientes();
    },

    nombreDefault: (lista) => `${lista.value}`,
    
    getPrecotizaciones() {
    this.loadingTable = true;
    axios
        .get(this.urlPreCotizacion)
        .then(response => { this.dataPreCotizaciones = response.data;
        })
        .catch(error => { console.log(error.response); })
        .finally(() => { this.loadingTable = false; })
    },
    getUsuarios() {
    axios
        .get("/Usuarios")
        .then(response => { this.dataUsuarios = response.data })
        .catch(error => { console.log(error.response) })
    },
    getClientes() {
    axios
        .get("/Clientes")
        .then(response => { this.dataClientes = response.data 
        //console.log("Soy de Geometrika");
        })
        .catch(error => { console.log(error.response) })
    },
    getCotizaciones() {
    this.loadingDetalles = true;
    axios
        .get(`/Cotizacion/Listar?IdPrecotizacion=${this.PCotizacionId}`)
        .then(response => { this.cotizacionesPre = response.data; })
        .catch(error => { console.log(error.response); })
        .finally(() => { this.loadingDetalles = false; })
    },

    putFolioRelacionado() {
    if (this.PCotizacionId && this.PCotizacionFolio) {
        this.loadingFolio = true;
        axios
        .put("/Precotizaciones/ActualizarFolioRelacionado", {
            idPrecotizacion: this.PCotizacionId,
            folioRelacionado: this.PCotizacionFolio,
        })
        .then((response) => {
            this.hide_dialogFolio();
            this.getPrecotizaciones()
            //this.alerta(200)
        })
        .catch((error) => {
            //this.alerta(error.response.status)
            console.log(error.response.status)
        })
        .finally(() => {
            this.loadingFolio = false;
        })
    }
    },
    putActualizarCompartir() {
    this.loadingCompartir = true;
    axios
        .put("/PrecotizacionUsuarios/Actualizar", {
        idPrecotizacion: this.preCompartirId,
        idUsuarioComparte: Number(this.idUsuario),
        datosUsuarioRecibe: this.dataUsuariosCompartidos
        })
        .then((response) => {
        this.hide_dialogCompartir();
        this.getPrecotizaciones();
        //this.alerta(200);
        })
        .catch((error) => {
        //this.alerta(error.response.status);
        console.log(error.response.status);
        })
        .finally(() => {
        this.loadingCompartir = false;
        })
    },

    mostrarOpcionesPre() {
    this.groupButtons = null;
    this.btnsTipoVaso = null;
    this.menuTipoPrecotizacion = true;
    },
    ocultarOpcionesPre() {
    this.menuTipoPrecotizacion = false;
    },

    mostrarDialogPreCot() {
    if (this.groupButtons == 1) { this.$refs.PreCotizacion.show_dialog(1); }
    else if (this.btnsTipoVaso >= 2) { this.$refs.PreCotizacion.show_dialog(this.btnsTipoVaso); }
    },

    mostrarDetalles(item) {
    this.cotizacionesPre = [];
    this.change_permisosCot(item)
    this.PCotizacionId = item.idPrecotizacion;
    Object.assign(this.dataDetallesCotizaciones, item);
    this.dialogDetalles = true;
    this.getCotizaciones();
    },
    ocultarDetalles() {
    this.PCotizacionId = null;
    this.dataDetallesCotizaciones = [];
    this.dialogDetalles = false;
    },

    show_dialogCotizacionG(item) {
    if (item) {
        this.$refs.dialogCotizacion.show_dialog(item.idPrecotizacion);
    }
    },

    show_Cotizacion(item) {
    if (item) {
        this.$refs.dialogCotizacion.show_Cotizacion(item.idCotizacion);
    }
    },
    show_editCotizacion(item) {
    if (item) {
        this.$refs.dialogCotizacion.show_editCotizacion(this.PCotizacionId, item.idCotizacion);
    }
    },

    show_dialogFolio(item) {
    this.dialogFolio = true;
    this.PCotizacionId = item.idPrecotizacion;
    this.PCotizacionFolio = item.folioRelacionado;
    },
    hide_dialogFolio() {
    this.PCotizacionFolio = this.PCotizacionId = this.valueDefault;
    this.dialogFolio = false;
    },

    show_mostrarCalculos(item) {
    const copiarOrden = new Promise((resolve, reject) => {
        try {
        this.idOrden = item.idCotizacion;
        resolve();
        } catch (error) {
        reject(error);
        }
    })
    copiarOrden
        .then(() => {
        this.$refs.MostrarCalculos.getMostrarCalculos();
        })
    },

    error_mostrarCalculos(error) {
    this.alerta(error);
    },
    show_mostrarCotizacion(item) {
    const copiarOrden = new Promise((resolve, reject) => {
        try {
        this.idOrden = item.idCotizacion;
        resolve();
        } catch (error) {
        reject(error);
        }
    })
    copiarOrden
        .then(() => {
        this.$refs.MostrarCotizacion.getMostrarCotizacion();
        })
        .catch(() => {
        this.alerta(500)
        })
    },
    error_mostrarCotizacion(code) {
    this.alerta(code);
    },

    cambioUrlPreCotizacion() {
    let params = {};

    if (this.selectCliente) params.IdCliente = this.selectCliente;
    if (this.selectEstado != null) params.Estado = this.selectEstado;

    const queryParms = new URLSearchParams(params).toString();
    this.urlPreCotizacion = `${this.urlPreCotizacionDefault}?${queryParms}`;
    this.getPrecotizaciones();
    },

    show_dialogCompartir(item) {
    this.dialogCompartir = true;
    this.preCompartirNombre = item.trabajo;
    this.preCompartirId = item.idPrecotizacion;
    this.dataUsuariosCompartidos = JSON.parse(JSON.stringify(item.usuariosPermisos))
    },
    hide_dialogCompartir() {
    this.dialogCompartir = false;
    this.preCompartirNombre = null;
    this.preCompartirId = null;
    this.dataUsuariosCompartidos = JSON.parse(JSON.stringify([]))
    },
    addUsuario() {
    this.dataUsuariosCompartidos.push(
        {
        idUsuarioRecibe: null,
        datosCotizacion: false,
        calculosCotizacion: false,
        actualizarCotizacion: false,
        crearPrecotizacion: false
        }
    );
    },
    removeUsuario(item) {
    const idx = this.dataUsuariosCompartidos.indexOf(item);
    this.dataUsuariosCompartidos.splice(idx, 1);
    },

    permisoAgregar(item) {
    const idx = this.dataPreCotizaciones.indexOf(item);

    const usuarioPrincipal = this.dataPreCotizaciones[idx].idUsuarioPrincipal;

    const crearPre = this.dataPreCotizaciones[idx].usuariosPermisos.find(usuario => usuario.idUsuarioRecibe == this.idUsuario)?.crearPrecotizacion;

    if (this.idUsuario == usuarioPrincipal) {
        return true;
    }

    if (crearPre) {
        return true;
    }
    return false;
    },
    permisosVer(item) {
    const idx = this.dataPreCotizaciones.indexOf(item);

    const usuarioPrincipal = this.dataPreCotizaciones[idx].idUsuarioPrincipal;

    const datosCot = this.dataPreCotizaciones[idx].usuariosPermisos.find(usuario => usuario.idUsuarioRecibe == this.idUsuario)?.datosCotizacion;
    const calculosCot = this.dataPreCotizaciones[idx].usuariosPermisos.find(usuario => usuario.idUsuarioRecibe == this.idUsuario)?.calculosCotizacion;
    const actualizarCot = this.dataPreCotizaciones[idx].usuariosPermisos.find(usuario => usuario.idUsuarioRecibe == this.idUsuario)?.actualizarCotizacion;

    if (this.idUsuario == usuarioPrincipal) {
        return true;
    }

    if (datosCot || calculosCot || actualizarCot) {
        return true;
    }
    return false;
    },
    change_permisosCot(item) {
    const idx = this.dataPreCotizaciones.indexOf(item);

    const usuarioPrincipal = this.dataPreCotizaciones[idx].idUsuarioPrincipal;

    const datosCot = this.dataPreCotizaciones[idx].usuariosPermisos.find(usuario => usuario.idUsuarioRecibe == this.idUsuario)?.datosCotizacion ? this.dataPreCotizaciones[idx].usuariosPermisos.find(usuario => usuario.idUsuarioRecibe == this.idUsuario)?.datosCotizacion : false;
    const calculosCot = this.dataPreCotizaciones[idx].usuariosPermisos.find(usuario => usuario.idUsuarioRecibe == this.idUsuario)?.calculosCotizacion ? this.dataPreCotizaciones[idx].usuariosPermisos.find(usuario => usuario.idUsuarioRecibe == this.idUsuario)?.calculosCotizacion : false;
    const actualizarCot = this.dataPreCotizaciones[idx].usuariosPermisos.find(usuario => usuario.idUsuarioRecibe == this.idUsuario)?.actualizarCotizacion ? this.dataPreCotizaciones[idx].usuariosPermisos.find(usuario => usuario.idUsuarioRecibe == this.idUsuario)?.actualizarCotizacion : false;

    if (this.idUsuario == usuarioPrincipal) {
        this.permisoVerDatos = true;
        this.permisoVerCalculos = true;
        this.permisoActualizarCot = true;
    } else {
        this.permisoVerDatos = datosCot;
        this.permisoVerCalculos = calculosCot;
        this.permisoActualizarCot = actualizarCot;
    }
    },
    permisoCompartir(item) {
    const idx = this.dataPreCotizaciones.indexOf(item);

    const usuarioPrincipal = this.dataPreCotizaciones[idx].idUsuarioPrincipal;

    if (this.idUsuario == usuarioPrincipal) {
        return true;
    }
    return false;
    },

    alerta(code, color, mensaje, icono) {
    if (code) {
        this.$refs.AlertSnackbar.state_alert(code);
    } else {
        this.$refs.AlertSnackbar.state_alert(null, color, mensaje, icono);
    }
    },

    succesPreCotizacion() {
    //this.alerta(200);
    this.menuTipoPrecotizacion = false; //Agregado para cerrar diálogo de elección pre-cotización
    this.getPrecotizaciones();
    },
    succesCotizacion() {
    //this.alerta(200)
    this.getPrecotizaciones();
    this.getCotizaciones()
    },
    error_Cotizacion(error) {
    this.alerta(error)
    },

    iniciarPreorden(item) {  //Llamada a función dede el componente "Preordenar.vue"
    this.$refs.Preordenar.abrirPreorden();
    const copiarOrden = new Promise((resolve, reject) => {
        try {
        this.idOrden = item.idCotizacion;
        resolve();
        } catch (error) {
        reject(error);
        }
    })
    copiarOrden
        .then(() => {
        this.$refs.Preordenar.getMostrarCotizacionPre();
        this.$refs.Preordenar.getMostrarCalculosPaPre();
        //this.$refs.Preordenar.getMostrarCotizacionPreO();
        })
        .catch((error) => {
        console.log(error)
        })
    },
    descargarPDF(idCotizacion) {
        if (!idCotizacion) return;
        const url = `/Cotizacion/GenerarCotizacionPDF?idCotizacion=${idCotizacion}`;
        const currentDate = new Date();
        const formattedDate = currentDate.toISOString().slice(0, 10); // Formato: YYYY-MM-DD
        const formattedTime = currentDate.toTimeString().slice(0, 8).replace(/:/g, '-'); // Formato: HH-MM-SS
        const fileName = `Cotizacion-${idCotizacion}_${formattedDate}_${formattedTime}.pdf`;
        this.$utils.axios.downloadFile(
            url,
            "GET",
            fileName, // Nombre del archivo con ID y fecha
            "application/pdf" // Tipo MIME para PDF
        );
    },
    infoCliente(item){
      this.idOrden = item.idCotizacion;
      this.getMostrarCalculos();
      //console.log(this.idOrden);
      //this.mostrarCliente = true;
    },
    cerrarCliente(){
      Object.assign(this.dataCostos, []);
      this.mostrarCliente = false;
    },
    getMostrarCalculos() {
      axios
        .get(`/Orden/MostrarCalculos/${this.idOrden}`)
        .then(response => {
          this.dataCostos = response.data;
          //this.dialog = true;
          this.mostrarCliente = true;
          //console.log(this.dataCostos);
         })
        .catch((error) => {
          this.$emit("error_mostrarCalculos", error.response.status);
          //this.dialog = false;
        })
    },
    formatearCifra(number, decimales = 2) {
    let numero = 0;
    if (number != null) numero = number;
    return numero.toLocaleString('en-US', { minimumFractionDigits: decimales, maximumFractionDigits: decimales });
    },
},
mounted() {
    this.idUsuario = localStorage.getItem("idUser")
    this.initialize();
}
};
</script>

<style scoped>
.my-button-class {
color: rgb(0, 123, 255) !important;
}

.v-btn--active.my-button-class {
background-color: rgb(0, 123, 255) !important;
color: white !important;
}

.my-title {
  color: #5c5c5c;
  margin-top: 50px;
  padding-bottom: 10px;
  width: 100%;
}

.padding-hr {
  padding: 0 0 15px 0;
}
</style>